import { Box, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { lighten } from "color2k";

import { getColor, getContrastingStyles } from "utils/theme";
import { getTertiaryColor } from "utils/customData";
import { getPlayerQuantity, getGlobalQuantity } from "utils/item";

export const ItemQuantity = ({
  loggedIn = false,
  item = {},
  themeOverride = {},
  globalTheme = {},
}) => {
  const primaryColor = getColor("primary", themeOverride, globalTheme);
  const color = getTertiaryColor(primaryColor);

  const playerQuantity = getPlayerQuantity(item, loggedIn);
  const globalQuantity = getGlobalQuantity(item);

  if (!playerQuantity && !globalQuantity) return null;

  return (
    <Box
      role="timer"
      bg={color}
      color={getContrastingStyles(color, "sectionPrimary").color}
      w="full"
      pb="4px"
      pt={"70px"}
      textAlign={"center"}
      borderRadius={"20px"}
      pos={"absolute"}
      bottom={{
        base: "-28px",
        md: "-30px",
      }}
      border={`4px solid ${lighten(color, 0.2)}`}
    >
      <Text variant="itemAvailability">
        {playerQuantity || globalQuantity}
      </Text>
    </Box>
  );
};

ItemQuantity.propTypes = {
  loggedIn: PropTypes.bool,
  item: PropTypes.object,
  themeOverride: PropTypes.object,
  globalTheme: PropTypes.object,
};
