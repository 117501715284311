import { theme as chakraTheme } from "@chakra-ui/react";
import { hexToRgb } from "./color";

import { ButtonVariants } from "styles/variants/button";
import { TextVariants } from "styles/variants/text";

export const MAX_WIDTH = 920;
export const MAX_HEIGHT = 300;
export const MAX_RATIO = MAX_WIDTH / MAX_HEIGHT;
const TRANSPARENT = "transparent";

const defaultWebshopTheme = {
  colors: {
    primary: "#ffffff",
    primaryContrast: "#ffffff",
    secondary: "#ffffff",
    secondaryContrast: "#ffffff",
    tertiary: "#D62410",
    tertiaryContrast: "#ffffff",
    text: "#000000",
    title: "#000000",
  },
  background: "#ffffff",
};

const isColorWhite = (color = "") => {
  const colorFormats = [
    "#ffffff",
    "#fff",
    "rgb(255,255,255)",
    "rgba(255,255,255,1)",
    "white",
  ];
  return colorFormats.includes(color.replace(/\s/g, "").toLowerCase());
};

export const getColor = (key = "", override = {}, global = {}) => {
  try {
    const color =
      override[key]?.color ||
      global[key]?.color ||
      defaultWebshopTheme.colors[key];
    return color;
  } catch (error) {
    return defaultWebshopTheme.colors.primary;
  }
};

export const getImageUrl = (files = [], name = "") => {
  try {
    const file = files.find((file) => file.name === name);
    return file?.base64 || file?.url || "";
  } catch (error) {
    return "";
  }
};

export const getHoverStyle = ({
  isHovered = false,
  themeOverride = {},
  globalTheme = {},
  colorKey = "primary",
}) => {
  const boxShadow = isHovered
    ? `0px 0px 8px 8px ${getColor(colorKey, themeOverride, globalTheme)}`
    : `0px 4px 4px 2px rgba(0, 0, 0, 0.3)`;
  return {
    boxShadow,
    transition: "box-shadow 0.2s ease",
  };
};

export const getBackgroundStyles = (
  theme = {},
  files = [],
  bgStyles = {},
  banner,
) => {
  try {
    if (!Array.isArray(files)) files = [];
    let bgColor = theme?.background?.color || TRANSPARENT;

    // Find the background image file
    let bgFile = null;
    let isMain = false;
    files.forEach((file) => {
      if (file.name === "MAIN" && !bgFile) {
        // Some sections might still have a MAIN image as the background
        bgFile = file;
        isMain = true;
      }
      if (file.name === "BACKGROUND_IMAGE") {
        // The real background image, overrides MAIN
        bgFile = file;
        isMain = false;
      }
    });

    if (bgFile) {
      if (bgColor === TRANSPARENT) bgColor = "#000000";
      // Base64 will come in from the builder, url is regular field
      const bgImage = `url(${bgFile.base64 || bgFile.url})`;
      const overlay = bgFile?.overlay || 0;
      const overlayOpacity = overlay / 100; // normalize overlay to range 0-1
      const rgbColor = hexToRgb(bgColor, overlayOpacity);
      const overlayBg = `linear-gradient(${rgbColor.rgba},${rgbColor.rgba}), ${bgImage}`;
      const imageStyles = {
        bg: overlayBg,
        bgPos: "center",
        bgRepeat: "no-repeat",
        bgSize: "cover",
        ...bgStyles,
      };
      if (isMain && banner) {
        imageStyles.filter = "blur(12px)";
        imageStyles.transform = "scale(1.1)";
      }
      return imageStyles;
    }

    return {
      bg: bgColor,
      ...bgStyles,
    };
  } catch (error) {
    console.log("Error getting background styles", error);
    return {
      bg: TRANSPARENT,
    };
  }
};

export const getContrastingStyles = (
  color = "",
  backupColor = "",
  opts = {},
) => {
  const { addShadow = false, excludeBg = false } = opts;
  // Returns "bg" and "color" styles for a given theme override
  let colors = {
    bg: "white",
    color: "black",
  };
  if (color && typeof color === "string") {
    colors.bg = color;
    colors.color = getContrastColor(color);
  } else if (backupColor) {
    colors.bg = backupColor;
    colors.color = backupColor + "Contrast";
  }
  if (isColorWhite(colors.color) && addShadow) {
    // Add a shadow to pure white text
    colors.textShadow = "0px 2px 0px rgba(0, 0, 0, 0.3)";
  }
  if (excludeBg) {
    delete colors.bg;
  }
  return colors;
};

export const getContrastColor = (hexColor) => {
  if (!hexColor) return "#000000";
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  // Calculate the relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  // Use the contrast formula to determine if white or black text is more readable
  const contrast =
    luminance > 0.5
      ? (Math.abs((luminance + 0.05) / 0.05) + 1) / 2
      : (Math.abs((luminance + 0.05) / 0.95) + 1) / 2;
  // Return black for higher contrast, white for lower contrast
  return contrast >= 4.5 ? "#000000" : "#FFFFFF";
};

const applyThemeColorsWithDefaults = (themeObject = {}) => {
  const { colors: defaultColors, background: defaultBackground } =
    defaultWebshopTheme;

  const colors = {};
  for (const [key, value] of Object.entries(defaultColors)) {
    colors[key] = themeObject[key]?.color || value;
  }

  return {
    colors,
    background:
      themeObject.background?.type === "COLOR"
        ? themeObject.background.color || defaultBackground
        : defaultBackground,
  };
};

export const generateTheme = (webshopData = {}) => {
  const preloadedData = window?.__PRELOADED_DATA__?.webshopCustomData || {};
  const headlineFont =
    webshopData?.storefront?.themeV2?.typography?.headline?.name;
  const regularFont =
    webshopData?.storefront?.themeV2?.typography?.regular?.name;

  const customBodyFontFamily = preloadedData?.theme?.fontFamily || regularFont;
  const customHeaderFontFamily =
    preloadedData?.theme?.fontFamilySectionHeader || headlineFont;

  const headingFont = `${customHeaderFontFamily}, ${chakraTheme.fonts.heading}`;
  const bodyFont = `${customBodyFontFamily}, ${chakraTheme.fonts.body}`;

  const { layout = {}, storefront = {} } = webshopData;
  let { header = {}, footer = {}, login = {}, deepLink = {} } = layout;
  if (!header) header = {};
  if (!footer) footer = {};
  if (!login) login = {};
  if (!deepLink) deepLink = {};

  const { theme: globalTheme = {} } = storefront;

  const webshopTheme = applyThemeColorsWithDefaults(globalTheme);
  const { colors = {} } = webshopTheme;

  const theme = {
    breakpoints: {
      base: "0px",
      sm: "320px",
      smd: "480px",
      md: "768px",
      lg: "960px",
      xl: "1200px",
      "2xl": "1536px",
    },
    colors: {
      ...colors,
      brandRed: "#ff5c79",
    },
    fonts: {
      body: bodyFont,
      heading: headingFont,
      sectionHeader: headingFont,
    },
    components: {
      Text: TextVariants,
      Button: ButtonVariants,
    },
  };

  const addColorsToTheme = (prefix = "", colorOverrides = {}) => {
    if (!colorOverrides || typeof colorOverrides !== "object") {
      colorOverrides = {};
    }
    ["primary", "secondary", "background", "title"].forEach((colorKey) => {
      const colorObj = colorOverrides[colorKey];
      const themeKey = `${prefix}${colorKey
        .charAt(0)
        .toUpperCase()}${colorKey.slice(1)}`;
      if (colorObj && colorObj.color) {
        theme.colors[themeKey] = colorObj.color;
      } else if (globalTheme[colorKey]) {
        theme.colors[themeKey] = globalTheme[colorKey].color;
      }
      // Add its contrast variant
      theme.colors[`${themeKey}Contrast`] = getContrastColor(
        theme.colors[themeKey],
      );
    });
  };

  addColorsToTheme("global", globalTheme);
  addColorsToTheme("header", header.themeOverride);
  addColorsToTheme("footer", footer.themeOverride);
  addColorsToTheme("login", login.themeOverride);
  addColorsToTheme("deepLink", deepLink.themeOverride);
  addColorsToTheme("section", {});
  addColorsToTheme("modal", login.themeOverride);

  return theme;
};

export const themeToCSSVariables = (theme = {}, globalTheme = {}) => {
  try {
    const cssVars = {};
    ["primary", "secondary", "background", "title"].forEach((colorKey) => {
      const colorObj = theme[colorKey] || {};
      let color = colorObj?.color || "";
      if (!color && globalTheme[colorKey]) {
        color = globalTheme[colorKey]?.color;
      }
      if (color) {
        cssVars[`--section-${colorKey}`] = color;
      }
    });
    return cssVars;
  } catch (error) {
    return {};
  }
};
