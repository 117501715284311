import {Heading } from "@chakra-ui/react";
import PropTypes from "prop-types";

import { getSectionHeaderStyles } from "utils/customData";

export const SectionHeader = ({
  section = {},
  ...rest
}) => {
  const { title = "", themeOverride = {} } = section;

  if (!title) {
    return null;
  }

  return (
    <Heading
      width={"100%"}
      textAlign={"center"}
      fontSize={{
        lg: "32px",
        md: "28px",
        base: "24px",
      }}
      pt="8px"
      mb={"24px"}
      fontWeight={600}
      lineHeight={{
        lg: "40px",
        md: "36px",
        base: "32px",
      }}
      letterSpacing={"-1px"}
      {...getSectionHeaderStyles(themeOverride, section)}
      fontFamily={"sectionHeader"}
      {...rest}
    >
      {title}
    </Heading>
  );
};

SectionHeader.propTypes = {
  section: PropTypes.object,
};
