import { getContrastingStyles } from "utils/theme";

import { SECTION_TYPES } from "consts/sections";

/*
  Example of the webshopCustomData object.
  This is from the LD webshop-custom-data flag, and
  gets passed in from the server to the client.

  There is a general aim to eventually phase all of this out

  {
    "meta": {
      "gtm": "ABCDE"
    },
    "overrides": {
      "freeLabel": "",
      "showFeaturedCTA": true,
      "showFeaturedTitle": false,
      "authEmailError": "Please enter a valid email",
    },
    "theme": {
      "featuredVariant": "variantOne",
      "featuredSectionHeaderVariant": "",
      "fontFamily": "Lato",
      "fontFamilySectionHeader": "Lato",
      "itemBodyColor": "#000000",
      "itemBodyField": "description",
      "itemBodyImageFit": "contain",
      "itemBodyVariant": "itemBodySingleLarge",
      "sectionHeaderVariant": "textOnly"
      "tertiaryColor": "#0000FF",
    }
  }
*/

const getThemeData = (key, defaultValue = "") => {
  try {
    const preloadedData = window?.__PRELOADED_DATA__?.webshopCustomData || {};
    return preloadedData?.theme?.[key] || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

const getOverrideData = (key, defaultValue = "") => {
  try {
    const preloadedData = window?.__PRELOADED_DATA__?.webshopCustomData || {};
    const value = preloadedData?.overrides?.[key];
    // Make sure false booleans are returned properly
    if (typeof value === "boolean") return value;
    if (!value) return defaultValue;
    return value;
  } catch (e) {
    return defaultValue;
  }
}

export const getItemBodyVariant = () => {
  return getThemeData("itemBodyVariant", "itemBodyMultiLine");
}

export const getFreeLabel = (defaultValue = "") => {
  return getOverrideData("freeLabel", defaultValue);
}

export const getAuthEmailError = () => {
  return getOverrideData("authEmailError", "I'm afraid that didn't work. Please make sure you entered your details correctly.");
}

export const getShowFeaturedCTA = () => {
  return getOverrideData("showFeaturedCTA", true);
}

export const getShowFeaturedTitle = () => {
  return getOverrideData("showFeaturedTitle", false);
}

export const getItemBodyField = (item) => {
  const field = getThemeData("itemBodyField", "name");
  let value = item[field];
  if (!value) value = item?.name;
  return value || "";
}

export const getItemBodyImageFit = () => {
  return getThemeData("itemBodyImageFit", "cover");
}

export const getItemBodyColor = () => {
  const color = getThemeData("itemBodyColor", "#000000");
  if (!color) return {};
  return { color };
}

export const getSectionHeaderStyles = (themeOverride = {}, section = {}) => {
  const { type = "" } = section;
  let key = "sectionHeaderVariant";
  if (type === SECTION_TYPES.FEATURED_ITEM) {
    key = "featuredSectionHeaderVariant";
  }
  const variant = getThemeData(key);
  if (!variant) {
    return {...getContrastingStyles(themeOverride?.title?.color)};
  }
  return {
    color: themeOverride?.title?.color || "black",
  };
}

export const getFeaturedVariant = () => {
  const variant = getThemeData("featuredVariant", "default");
  return `featured_${variant}`;
}

export const getTertiaryColor = (defaultColor = "") => {
  return getThemeData("tertiaryColor", defaultColor);
}
