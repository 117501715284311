import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { sectionsSelector, storefrontSelector } from "features/app/appSlice";
import PropTypes from "prop-types";
import { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toKebabCase } from "utils/general";
import { getBackgroundStyles, getContrastColor } from "utils/theme";
import ChevronDown from "assets/img/icons/chevron-down.svg?react";

export function SectionsLinks({ section = {}, selected = false }) {
  const [isLargerThan1024] = useMediaQuery("(min-width: 1024px)");
  const { theme } = useSelector(storefrontSelector);
  const sections = useSelector(sectionsSelector);
  const containerRef = useRef();
  const [[links, menuLinks], setLinks] = useState([[], []]);

  const { themeOverride, files } = section;
  const backgroundColor =
    section.themeOverride?.background?.color ?? theme?.background?.color;
  const buttonColor =
    section.themeOverride?.primary?.color ?? theme?.primary?.color;
  const textColor =
    section.themeOverride?.secondary?.color ?? getContrastColor(buttonColor);

  /*
    So we try to avoid the flickering of the links when the user is loading. Ideally we should get from the node server if the user is seeing
    the page from a mobile device or not.
    */
  useLayoutEffect(() => {
    const filteredSections = sections.filter((s) => s.title?.trim().length > 0);
    if (isLargerThan1024 && filteredSections.length > 5) {
      setLinks([
        filteredSections.slice(0, 4),
        filteredSections.slice(4, sections.length),
      ]);
    } else {
      setLinks([filteredSections, []]);
    }
  }, [sections, isLargerThan1024]);

  function handleClick(e, section) {
    e.preventDefault();
    const sectionTitle = toKebabCase(section?.title);
    const element = document.getElementById(`section-${sectionTitle}`);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
  if (sections.length === 0) return null;

  return (
    <Box
      bg={backgroundColor}
      w="100%"
      {...getBackgroundStyles(themeOverride, files)}
      data-scroll-target={`section-${section.id}`}
      outline={`2px solid ${
        selected ? "var(--chakra-colors-brandRed)" : "transparent"
      }`}
      outlineOffset={"-8px"}
    >
      <Box mx={"auto"} pos={"relative"} maxW={"var(--max-width)"}>
        <Flex
          as={"nav"}
          gap={{ base: "8px", md: "32px" }}
          mx="auto"
          py={"24px"}
          px="16px"
          flexWrap={"nowrap"}
          overflowX={"auto"}
          justifyContent={{ base: "flex-start", lg: "center" }}
          ref={containerRef}
        >
          {links.map(
            (link) =>
              link?.title && (
                <Link
                  key={link?.id}
                  flex={"0 0 fit-content"}
                  maxW={"280px"}
                  minW={"96px"}
                  href={"#"}
                  py="8px"
                  px="12px"
                  border={`1px solid ${buttonColor}`}
                  _hover={{
                    textDecoration: "none",
                    filter: "brightness(1.12)",
                  }}
                  bg={buttonColor}
                  borderRadius={"12px"}
                  onClick={(e) => handleClick(e, link)}
                >
                  <Text
                    w={"100%"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    textAlign={"center"}
                    whiteSpace={"nowrap"}
                    fontSize={"14px"}
                    color={textColor}
                    fontWeight={600}
                    lineHeight={"16px"}
                  >
                    {link.title}
                  </Text>
                </Link>
              ),
          )}
          {isLargerThan1024 && menuLinks.length > 0 && (
            <Menu py={"24px"} px="16px">
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={Button}
                    bg={buttonColor}
                    color={textColor}
                    fontSize={"14px"}
                    fontWeight={600}
                    borderRadius={"12px"}
                    _active={{ bg: buttonColor, filter: "brightness(1.12)" }}
                    _hover={{ bg: buttonColor, filter: "brightness(1.12)" }}
                    rightIcon={
                      <Icon
                        transform={isOpen ? "rotateX(180deg)" : "rotateX(0)"}
                        transition={"transform 0.2s ease-out"}
                        size="xs"
                        as={ChevronDown}
                      />
                    }
                    h={"auto"}
                    flex={"0 0 fit-content"}
                    maxW={"280px"}
                  >
                    More
                  </MenuButton>
                  <MenuList
                    bg={buttonColor}
                    borderColor={buttonColor}
                    borderRadius={"12px"}
                    zIndex={3}
                  >
                    {menuLinks.map(
                      (link) =>
                        link?.title && (
                          <MenuItem
                            as={Link}
                            _hover={{
                              textDecoration: "none",
                              filter: "brightness(1.12)",
                            }}
                            fontWeight={600}
                            fontSize={"14px"}
                            bg={buttonColor}
                            color={textColor}
                            key={link?.id}
                            onClick={(e) => handleClick(e, link)}
                          >
                            {link.title}
                          </MenuItem>
                        ),
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </Flex>
      </Box>
    </Box>
  );
}

SectionsLinks.propTypes = {
  section: PropTypes.object,
  selected: PropTypes.bool,
};
