export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const array = new Uint32Array(length);
  crypto.getRandomValues(array);
  let nonce = "";
  for (let i = 0; i < length; i++) {
    nonce += characters.charAt(array[i] % charactersLength);
  }
  return nonce;
};
