import { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { ErrorBoundary } from "components/error-boundary";
import { useAuthentication } from "src/hooks/useAuthentication";

import { settingsSelector } from "features/app/appSlice";

export const PasswordAuth = ({ editorMode = false, provider }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    values: {
      authMethod: provider.authMethod || provider.type,
      credentials: {
        email: "",
        password: "",
      },
    },
  });
  const { handleLogin, isLoading, error } = useAuthentication();
  const {
    errorMessage = "",
    instructionText = "",
    visible = false,
  } = provider;
  const { supportLink, supportLinkLabel } = useSelector(settingsSelector);
  const [showPassword, setShowPassword] = useState(false);

  function onSubmit(data) {
    if (editorMode) return;
    handleLogin(data);
  }

  const supportText =
    supportLink && supportLinkLabel ? (
      <Text fontSize={"sm"} mt="4px">
        Forget your password or need help? Contact us at{" "}
        <Link isExternal href={supportLink} textDecor={"underline"}>
          {supportLinkLabel}
        </Link>
      </Text>
    ) : null;

  return (
    <ErrorBoundary name="password-auth" show={visible}>
      <VStack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        mb="32px"
        gap="16px"
        _last={{ mb: 0 }}
      >
        {instructionText && (
          <Text variant="loginGeneric">
            {instructionText}
          </Text>
        )}

        <Box w="full">
          <FormControl isInvalid={false} mb={"16px"}>
            <Input
              {...register("credentials.email", { required: true })}
              type="email"
              flex={"1"}
              size="lg"
              color={error ? "red.500" : "black"}
              fontWeight="600"
              borderRadius={"24px"}
              border={"2px solid rgba(22, 23, 25, 0.15)"}
              boxShadow={"sm"}
              padding={"0 4px 0 16px"}
              outline={"none"}
              placeholder="Email"
              _placeholder={{
                fontSize: "sm",
              }}
              _active={{
                outline: "none !important",
              }}
              _focus={{
                outline: "none !important",
              }}
              _focusVisible={{
                outline: "none !important",
              }}
            />
            <FormErrorMessage p={"0"} mt={"0"} height={"24px"}>
              Email required
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={false} mb={0}>
            <InputGroup>
              <Input
                {...register("credentials.password", { required: true })}
                type={showPassword ? "text" : "password"}
                flex={"1"}
                size="lg"
                color={error ? "red.500" : "black"}
                fontWeight="600"
                borderRadius={"24px"}
                border={"2px solid rgba(22, 23, 25, 0.15)"}
                boxShadow={"sm"}
                padding={"0 4px 0 16px"}
                outline={"none"}
                placeholder="Password"
                _placeholder={{
                  fontSize: "sm",
                }}
                _active={{
                  outline: "none !important",
                }}
                _focus={{
                  outline: "none !important",
                }}
                _focusVisible={{
                  outline: "none !important",
                }}
              />
              <InputRightElement
                h={"100%"}
                right={"8px"}
                color={"gray.500"}
                aria-label={showPassword ? "Hide password" : "Show password"}
                cursor={"pointer"}
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage p={"0"} mt={"0"} height={"24px"}>
              Password required
            </FormErrorMessage>
          </FormControl>
        </Box>

        <Button
          bg={"loginPrimary"}
          padding={"12px 18px"}
          w={"160px"}
          borderRadius={"48px"}
          position={"relative"}
          cursor={editorMode ? "not-allowed" : "pointer"}
          color={"loginPrimaryContrast"}
          boxShadow={"md"}
          isDisabled={editorMode || !isValid}
          isLoading={isLoading}
          type="submit"
        >
          Submit
        </Button>

        {error && (
          <Box border="1px solid transparent" borderColor={"red.500"} p={"8px"}>
            <Text fontSize={"sm"} dangerouslySetInnerHTML={{ __html: errorMessage }} />
            {supportText}
          </Box>
        )}
      </VStack>
    </ErrorBoundary>
  );
};

PasswordAuth.propTypes = {
  skeleton: PropTypes.bool,
  provider: PropTypes.object,
  editorMode: PropTypes.bool,
};
