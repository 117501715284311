export const ButtonVariants = {
  variants: {
    featured_default: {
      width: "33%",
      height: "32%",
      maxHeight: "120px",
    },
    featured_variantOne: {
      width: "42%",
      height: "16%",
      maxHeight: "60px",
    }
  },
};
