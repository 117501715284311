import {
  Button,
  CardFooter,
} from "@chakra-ui/react";

import { getPrice } from "utils/general";
import { getContrastingStyles } from "utils/theme";

export const ItemFooter = ({
  loggedIn = false,
  item = {},
  pricingTiers = {},
  themeOverride = {},
}) => {
  const { pricingTierLevelId = "" } = item;
  const itemPrice = getPrice(pricingTiers[pricingTierLevelId], {
    loggedIn,
  });

  return (
    <CardFooter
      p={{
        base: "0",
      }}
    >
      <Button
        variant={"solid"}
        width={"100%"}
        height={"auto"}
        padding={{
          base: "12px 8px",
          smd: "16px 12px",
        }}
        textAlign={"center"}
        borderRadius={"0"}
        _hover={{
          filter: "brightness(1.2)",
        }}
        _active={{
          filter: "brightness(0.8)",
        }}
        fontWeight={700}
        fontSize={{
          base: "14px",
          smd: "22px",
        }}
        lineHeight={{
          base: "16px",
          smd: "22px",
        }}
        {...getContrastingStyles(themeOverride?.primary?.color, "sectionPrimary", {
          addShadow: true,
        })}
      >
        {itemPrice}
      </Button>
    </CardFooter>
  );
};
