import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  purchaseThunks,
  purchasePlayerSelector,
  purchaseSessionSelector,
  purchaseSessionInfoSelector,
} from "features/purchase/purchaseSlice";
import { shopSelector } from "features/shop/shopSlice";
import { pricingTiersSelector } from "features/app/appSlice";

import { getPrice } from "utils/general";
import ArrowRight from "images/icons/arrow-right.svg?react";
import debounce from "lodash.debounce";

import PropTypes from "prop-types";

import { ErrorBoundary } from "components/error-boundary";
import { TRACKING_EVENTS, useTrackEvent } from "utils/tracking";
import {
  EMAIL_ERROR_MESSAGE,
  ZIP_CODE_ERROR_MESSAGE,
  isValidEmail,
  isValidZipCode,
} from "utils/form";

export const PurchaseReviewContainer = () => {
  const dispatch = useDispatch();
  const {
    clientStorefrontZipValidation = false,
  } = useFlags();

  const { currentViewedItem = null } = useSelector(shopSelector);
  const session = useSelector(purchaseSessionSelector);
  const pricingTiers = useSelector(pricingTiersSelector);
  const sessionInfo = useSelector(purchaseSessionInfoSelector);

  const {
    zip: playerZip,
    email: playerEmail,
  } = useSelector(purchasePlayerSelector);

  const debouncedUpdateSession = debounce((data) => {
    dispatch(purchaseThunks.updateSession(data));
  }, 1000);

  useTrackEvent(TRACKING_EVENTS.ITEM_REVIEW, { item: currentViewedItem });

  return (
    <PurchaseReview
      sessionInfo={sessionInfo}
      item={currentViewedItem}
      pricingTier={pricingTiers[currentViewedItem?.pricingTierLevelId]}
      playerZip={playerZip}
      playerEmail={playerEmail}
      session={session}
      onFormSubmit={() => {
        // Cancel any pending debounced updates
        debouncedUpdateSession.cancel();
        dispatch(purchaseThunks.confirmSession());
      }}
      debouncedUpdateSession={debouncedUpdateSession}
      clientStorefrontZipValidation={clientStorefrontZipValidation}
    />
  );
};

export const PurchaseReview = ({
  sessionInfo = {},
  item = {},
  pricingTier = {},
  playerZip = "",
  playerEmail = "",
  session = {},
  onFormSubmit = () => {},
  debouncedUpdateSession = () => {},
  clientStorefrontZipValidation = false,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFormSet, setIsFormSet] = useState(false);

  const {
    sessionV2: {
      price: {
        taxAmount = 0,
        vatAmount = 0,
        vatPercentage = "",
        totalAmount = "",
        isEstimated = false,
      } = {},
      zipRequired = false,
    } = {},
  } = session;

  const defaultValues = {
    email: playerEmail || "",
  };
  if (zipRequired) {
    defaultValues.zip = playerZip || "";
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues,
  });

  const formValues = watch();
  const { email, zip } = formValues;

  const priceAmount = getPrice(pricingTier, {
    loggedIn: true, // At purchase review, user is always logged in
  });
  const itemTotalAmount = totalAmount || priceAmount;

  useEffect(() => {
    let valid = true;

    if (zipRequired) {
      const validZip = isValidZipCode(zip, {
        basic: !clientStorefrontZipValidation,
      });
      if (validZip !== true) valid = false;
    }
    const validEmail = isValidEmail(email);
    if (validEmail !== true) valid = false;

    setIsDisabled(!valid);
    if (!valid) return;

    setIsTyping(true);
    debouncedUpdateSession({ email, zip });
  }, [email, zip]);

  useEffect(() => {
    // Load in the "default" values if they are not already set
    // These are async from the request, and not available on first render
    if (isFormSet) return;
    if (!playerZip && !playerEmail) return;
    // Set the values if they are not already set
    if (zipRequired && playerZip && !formValues.zip) {
      setValue('zip', playerZip);
    }
    if (playerEmail && !formValues.email) {
      setValue('email', playerEmail);
    }
    setIsFormSet(true);
  }, [playerZip, playerEmail, formValues, setValue, zipRequired]);

  useEffect(() => {
    if (sessionInfo.update.ready || sessionInfo.update.error) {
      setIsTyping(false);
    }
  }, [sessionInfo.update]);

  return (
    <VStack
      flex={1}
      maxW={"100%"}
      w={"auto"}
      p="40px"
      gap={"24px"}
      alignItems={"start"}
    >
      <Heading size={"lg"} color="modalTitle">
        Review your purchase
      </Heading>

      <HStack color="modalTitle" alignItems={"start"} w={"full"}>
        <Image
          src={item?.imageUrl}
          alt={item?.name}
          w={"126px"}
          h={"126px"}
          objectFit={"contain"}
          borderRadius={"8px"}
        />
        <VStack flexGrow={1} alignItems={"start"} gap={0}>
          <Text fontSize={"24px"}>{item?.name}</Text>
          <VStack
            mt={"8px"}
            w="100%"
            alignItems={"start"}
            gap={0}
            color="rgba(22, 23, 25, 0.50)"
            fontSize={"16px"}
            fontWeight={500}
          >
            <HStack justifyContent={"space-between"} w={"full"}>
              <Text>Price</Text>
              <Text variant="price">{priceAmount}</Text>
            </HStack>
            {(taxAmount && !vatAmount) && (
              <HStack justifyContent={"space-between"} w={"full"}>
                <Text>{isEstimated ? "Estimated sales tax" : "Sales tax"}</Text>
                <Text variant="price">{taxAmount}</Text>
              </HStack>
            )}
            {vatAmount && (
              <HStack justifyContent={"space-between"} w={"full"}>
                <Text>Including {vatPercentage} VAT</Text>
                <Text variant="price">{vatAmount}</Text>
              </HStack>
            )}
            <HStack
              mt="4px"
              mb="4px"
              w={"full"}
              h={"1px"}
              bg={"rgba(22, 23, 25, 0.15)"}
            />
            <HStack justifyContent={"space-between"} w={"full"}>
              <Text>{(isEstimated && !vatAmount)? "Estimated TOTAL" : "TOTAL"}</Text>
              <Text fontWeight="bold" color="modalPrimary" variant="price">
                {itemTotalAmount}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </HStack>

      <HStack w={"full"} h={"1px"} bg={"rgba(22, 23, 25, 0.15)"} />

      <ErrorBoundary
        name="purchase-review-modal"
        altUI={(
          <HStack align={"center"} justify={"center"} w={"full"} h={"100%"}>
            <Spinner size={"xl"} />
          </HStack>
        )}
        showAltUI={!sessionInfo.create.ready}
        show={sessionInfo.create.ready}
      >
        <form
          id={"purchase-review-form"}
          onSubmit={handleSubmit(onFormSubmit)}
          style={{ width: "100%" }}
        >
          <HStack
            gap={"8px"}
            width="100%"
            alignItems="center"
            borderRadius={"24px"}
            border={"2px solid rgba(22, 23, 25, 0.15)"}
            boxShadow={"0px 2px 3px 2px rgba(90, 90, 90, 0.11)"}
            padding={"4px"}
            justifyContent={"space-between"}
          >
            <VStack flexGrow={1}>
              {zipRequired && (
                <>
                  <FormControl w={"full"}>
                    <FormLabel m="0">
                      <Input
                        readOnly={sessionInfo.confirm.loading}
                        autoFocus={zipRequired}
                        w={"full"}
                        p={"12px"}
                        borderRadius={"10px"}
                        border="0"
                        color="black"
                        placeholder={"ZIP Code"}
                        defaultValue={playerZip}
                        focusBorderColor={"transparent"}
                        {...register("zip", {
                          required: ZIP_CODE_ERROR_MESSAGE,
                          validate: (value) => isValidZipCode(value, {
                            basic: !clientStorefrontZipValidation,
                          }),
                        })}
                        _active={{
                          border: "none !important",
                          outline: "none !important",
                        }}
                        _focus={{
                          border: "none !important",
                          outline: "none !important",
                        }}
                        _focusVisible={{
                          border: "none !important",
                          outline: "none !important",
                        }}
                      />
                    </FormLabel>
                    {errors.zip && (
                      <Text
                        m={"8px 0px 0px 12px"}
                        color={"red"}
                        fontSize={"14px"}
                        lineHeight={"18px"}
                      >
                        {errors.zip.message}
                      </Text>
                    )}
                  </FormControl>
                  {/* horizontal line spacer */}
                  <HStack w={"full"} h={"1px"} bg={"rgba(22, 23, 25, 0.15)"} />
                </>
              )}
              <FormControl w={"full"}>
                <FormLabel m="0">
                  <Input
                    readOnly={sessionInfo.confirm.loading}
                    autoFocus={!zipRequired}
                    w={"full"}
                    p={"12px"}
                    borderRadius={"10px"}
                    border="0"
                    color="black"
                    placeholder={"Email"}
                    defaultValue={playerEmail}
                    focusBorderColor={"transparent"}
                    {...register("email", {
                      required: EMAIL_ERROR_MESSAGE,
                      validate: isValidEmail,
                    })}
                    _active={{
                      border: "none !important",
                      outline: "none !important",
                    }}
                    _focus={{
                      border: "none !important",
                      outline: "none !important",
                    }}
                    _focusVisible={{
                      border: "none !important",
                      outline: "none !important",
                    }}
                  />
                </FormLabel>
                {errors.email && (
                  <Text
                    m={"8px 0px 0px 12px"}
                    color={"red"}
                    fontSize={"14px"}
                    lineHeight={"18px"}
                  >
                    {errors.email.message}
                  </Text>
                )}
              </FormControl>
            </VStack>
            <Button
              type={"submit"}
              form={"purchase-review-form"}
              borderRadius={"48px"}
              isLoading={isTyping || sessionInfo.update.loading || sessionInfo.confirm.loading}
              isDisabled={isDisabled || isTyping || !sessionInfo.update.ready}
              bg={"modalPrimary"}
              color={"modalPrimaryContrast"}
              height={zipRequired ? "96px" : "48px"}
              _hover={{
                filter: "brightness(1.12)",
              }}
              _active={{
                filter: "brightness(0.85)",
              }}
            >
              <Icon
                as={ArrowRight}
                fill={"white"}
                height={"24px"}
                width={"24px"}
              />
            </Button>
          </HStack>

          {sessionInfo.update.error && (
            <Text mt={"8px"} color={"red"} fontSize={"14px"} lineHeight={"18px"}>
              {`Something went wrong. Update your email${
                zipRequired ? " or ZIP Code " : " "
              }and try again.`}
            </Text>
          )}

          <Text mt="4px" fontSize="12px" color="rgba(22, 23, 25, 0.50)">
            The purchase receipt will be sent to the email provided.
          </Text>
        </form>
      </ErrorBoundary>
    </VStack>
  );
};

PurchaseReview.propTypes = {
  sessionInfo: PropTypes.object,
  item: PropTypes.object,
  pricingTier: PropTypes.object,
  playerZip: PropTypes.string,
  playerEmail: PropTypes.string,
  session: PropTypes.object,
  onFormSubmit: PropTypes.func,
  debouncedUpdateSession: PropTypes.func,
  clientStorefrontZipValidation: PropTypes.bool,
};
